/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/theming';
@import "src/assets/styles/variables";
@include mat-core();
$primary: mat-palette($custom-pink-palette, 500);
$userLogin: mat-palette($custom-pink-palette, F1);
$accent: mat-palette($mat-orange, 200);
$theme: mat-light-theme($primary, $accent, $userLogin);
@include angular-material-theme($theme);
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

img {
    max-width: 100%;
}

* {
    box-sizing: border-box;
}

.mat-table {
    width: 100%;
}

.flex {
    display: flex;
    &.space-between {
        justify-content: space-between;
    }
    &.align-center {
        align-items: center;
    }
    &.justify-center {
        justify-content: center;
    }
    &.dir-column {
        flex-direction: column;
    }
    //> * {
    //  align-self: center;
    //}
    &__1-2 {
        width: 98%;
        padding: 0 20px;
    }
    &__2-3 {
        width: calc(100% / 3 * 2);
        padding: 0 20px;
    }
    &__1-3 {
        width: calc(100% / 3);
        padding: 0 20px;
    }
}

.image-zoom {
    border: 1px solid #3f51b5;
    cursor: zoom-in;
}

a {
    text-decoration: none;
    color: inherit;
}

.custom-snackbar {
    background-color: #3f51b5;
    span {
        color: #ffffff;
    }
}

.table-image {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

td.mat-cell {
    &:not(:first-child) {
        padding: 0 5px !important;
        max-width: 200px;
    }
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.key-values {
    .row {
        display: flex;
        .key {
            font-weight: bold;
            margin-right: 10px;
        }
    }
}

.text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    background-color: #ff4081 !important;
}

.mat-radio-inner-circle {
    background-color: #ff4081 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    background-color: white;
}

.mat-drawer ::-webkit-scrollbar {
    display: none !important;
}

.table-container {
    overflow: auto;
    max-height: 650px;
    /* Adjust the max-height as needed */
}

.table-container::-webkit-scrollbar {
    display: none !important
}

th,
td {
    white-space: nowrap;
}

.mat-header-row {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff;
    /* Set your desired background color */
}